import { Component, OnInit } from '@angular/core';
import { HarvestDeliveryNote } from '@core/models/harvest-delivery-note.model';
import { PopupFormComponent } from '@core/base/popup-form.class';
import { Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ApiService, ListOptions } from '@capturum/api';
import { HarvestDeliveryNoteService } from '@core/api/harvest-delivery-note.service';
import { MapItemAttribute } from '@core/models/map-item-attribute.model';
import { Store } from '@ngxs/store';
import { ReloadGradingCards } from '@store/admin/grading/grading.actions';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GradingInLoadService } from '@core/api/grading-inload.service';

@Component({
  selector: 'app-start-grading-popup',
  templateUrl: './start-grading-popup.component.html',
  styleUrls: ['./start-grading-popup.component.scss'],
})
export class StartGradingPopupComponent extends PopupFormComponent<HarvestDeliveryNote> implements OnInit {
  public harvest: HarvestDeliveryNote;
  public gradingStations: Observable<MapItemAttribute[]>;

  constructor(
    protected apiService: ApiService<HarvestDeliveryNote>,
    protected formBuilder: UntypedFormBuilder,
    protected harvestDeliveryNoteService: HarvestDeliveryNoteService,
    private gradingInLoadService: GradingInLoadService,
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private store: Store,
  ) {
    super(apiService);

    this.harvest = this.config?.data?.harvest;
    this.gradingStations = this.config?.data?.gradingStations;
  }

  public updateValue(nr: number, field: string): void {
    const bunches = this.form.get(field) as UntypedFormControl;
    const value = (Number(bunches?.value) || 0) + nr;

    bunches.setValue(Math.max(value, 1));
  }

  public ngOnInit(): void {
    this.createForm();
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  protected createForm(): void {
    this.form = this.formBuilder.group({
      harvest_delivery_note_id: this.harvest?.id,
      grading_station_id: [null, Validators.required],
      bunches: [null, [Validators.required, Validators.min(0)]],
      stems: [null, [Validators.required, Validators.min(0)]],
    });

    this.form
      .get('bunches')
      .valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((packRate) => {
        const stemCount: number = Number(packRate) * Number(this.harvest?.pack_rate);

        this.form.get('stems').setValue(stemCount);
      });
  }

  protected doAction(data: any, options?: ListOptions): Observable<any> {
    return this.gradingInLoadService.startGrading({ ...data, bunches: +data?.bunches });
  }

  protected showSuccessToast(entity?: any): void {
    const title = this.translateService.instant('toast.success.title');
    const message = this.translateService.instant('dxp.grading-session.started');

    this.notificationService.success(title, message);
  }

  protected onSuccessfulSubmit(updatedHarvest: HarvestDeliveryNote): void {
    this.store.dispatch(new ReloadGradingCards());

    this.harvest = {
      ...updatedHarvest,
      production: { ...this.harvest?.production },
    };

    this.form.patchValue({
      grading_station_id: this.form?.value?.grading_station_id, // Remember selected station
    });

    this.onClose();
  }
}
