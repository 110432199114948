<form [formGroup]="form">
  <div class="container-fluid container-size">
    <div class="row pb-3">
      <div class="col-12">
        <div class="form-group mb-3">
          <!-- TODO: replace with form control accessor that can be reused after production -->
          <label>{{ 'dxp.harvest-delivery-note.buckets.label' | translate }}</label>

          <div class="d-flex input-number-increment">
            <cap-button styleClass="icon minus secondary" icon="fas fa-minus" (onClick)="updateValue(-1, 'bunches')">
            </cap-button>

            <cap-input formControlName="bunches" type="number"></cap-input>

            <cap-button styleClass="icon plus secondary" icon="fas fa-plus" (onClick)="updateValue(1, 'bunches')">
            </cap-button>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="form-group mb-3">
          <!-- TODO: replace with form control accessor that can be reused after production -->
          <label>{{ 'dxp.harvest-delivery-note.stems.label' | translate }}</label>

          <div class="d-flex input-number-increment">
            <cap-button
              styleClass="icon minus secondary"
              icon="fas fa-minus"
              (onClick)="updateValue(-harvest?.pack_rate || -1, 'stems')">
            </cap-button>

            <cap-input formControlName="stems" type="number"></cap-input>

            <cap-button
              styleClass="icon plus secondary"
              icon="fas fa-plus"
              (onClick)="updateValue(harvest?.pack_rate || 1, 'stems')">
            </cap-button>
          </div>
        </div>
      </div>

      @if (gradingStations) {
        <div class="col-12">
          <app-select-list-item
            formControlName="grading_station_id"
            noActiveItemsTranslationKey="dxp.grading.station-disabled"
            disabledItemTranslationKey="dxp.grading.station-disabled"
            labelTranslationKey="dxp.sorting-station.title"
            [items]="gradingStations">
          </app-select-list-item>
        </div>
      }
    </div>

    <div class="row mb-2">
      <div class="col d-flex justify-content-center">
        <cap-button styleClass="secondary mb-2" [label]="'button.cancel' | translate" (onClick)="onClose()">
        </cap-button>

        <cap-button
          appPreventDoubleClick
          styleClass="primary ml-2"
          [label]="'button.submit' | translate"
          [icon]="formConfig.submitIcon$ | async"
          [disabled]="form.invalid"
          (clickEvent)="submit()">
        </cap-button>
      </div>
    </div>
  </div>
</form>
