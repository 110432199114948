import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult } from '@capturum/api';
import { HarvestDeliveryNote } from '@core/models/harvest-delivery-note.model';

@Injectable({
  providedIn: 'root',
})
export class GradingInLoadService extends ApiService<HarvestDeliveryNote> {
  protected endpoint = 'grading-inload';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public startGrading(payload: Partial<HarvestDeliveryNote>): Observable<HarvestDeliveryNote> {
    const url = `/${this.endpoint}/${payload.harvest_delivery_note_id}/start-grading`;

    return this.apiHttp.patch<ApiSingleResult<HarvestDeliveryNote>>(url, payload).pipe(
      map((response) => {
        return response?.data;
      }),
    );
  }
}
